import service from "./api";

export default {
  computed: {
    codeBtn() {
      return this.seconds ? this.seconds + " s" : "获取验证码";
    }
  },
  methods: {
    //   获取验证码
    getCode(type, extData = {}) {
      // 校验手机号
      let { mobile = "" } = this[type];
      let check = false;
      this.$refs[type].validateField("mobile", err => {
        check = !err;
      });
      if (!check || this.seconds > 0) return;
      clearTimeout(this.timer);
      return service.getCode({ phone: mobile, ...extData }).then(() => {
        let now = new Date().getTime() + 60 * 1000;
        this.till = now;
        this.countDown();
      });
    },
    // 倒计时
    countDown() {
      // 当前秒数取整
      let seconds = ~~((this.till - new Date().getTime()) / 1000);
      if (seconds > 0) {
        this.seconds = seconds;
        this.timer = setTimeout(() => {
          this.countDown();
        }, 1000);
      } else {
        this.seconds = 0;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};

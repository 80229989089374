<template>
  <div style="z-index: 20;">
    <el-row :gutter="20">
      <el-col :span="14">
        <el-card class="card-1">
          <div style="margin-bottom: 20px;font-size: 16px; font-weight: bold;">快捷入口</div>
          <div style="display: flex;justify-content: space-between;">
            <div v-for="(item, index) in entry" :key="index" style="text-align: center;" @click="openPage(item)">
              <div class="kjrk">
                <img style="height: 60%; top:50%" :src="item.icon" />
              </div>
              <div style="margin-top: 10px;color: #666666;font-weight: 500; font-size: 14px;">{{ item.name }}</div>
            </div>
             <!--修改密码-->
             <info ref="resetRef"></info>
          </div>
        </el-card>
        <div>
          <!-- 统计卡片 -->
          <div class="card-2" style="display: none; margin-bottom: 20px;">
            <div class="card ft-bold" v-for="(item, index) in cardList" :key="index">
              <!-- <i class="card-icon" :class="item.icon"></i> -->
              <span class="card-name ft-sz-12 c-white" :style="{ backgroundColor: item.color }">{{ item.name }}</span>
              <div class="ft-sz-30" style="margin-top: 0px">{{ item.data.num || 0 }}</div>
              <div class="card-trend ft-sz-14" style="color: #718096">
                较昨日
                <span :class="Number(item.data.ratio) > 0 ? 'up' : 'down'">
                  <i :class="Number(item.data.ratio) > 0 ? 'el-icon-top-right' : 'el-icon-bottom-right'"></i>
                  {{ Math.abs(Number(item.data.ratio) || 0) }}%
                </span>
              </div>
            </div>
          </div>
          <!-- 统计曲线图 -->

        </div>
      </el-col>
      <el-col :span="10" class="gg">
        <!-- <div class="ad01 aders">承接搭建独立风控系统，安全无忧</div>
        <div class="ad02 aders">搭建电子合同，小程序和公众号</div> -->
        <el-card class="">
          <div style="margin-bottom: 20px;font-size: 16px; font-weight: bold;">最新公告</div>
          <div class="gonggao">
            <span class="time">2024-10-01</span>
            <div class="gg-info">
              新增真假财务免费查询功能，数据库不断更新中，欢迎商户踊跃参与。
            </div>
          </div>
          <div class="gonggao">
            <span class="time">2024-09-01</span>
            <div class="gg-info">
              新增黑名单库，商户可以共享共用库，也可以自己录入新的黑名单。
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="tjt" style="margin: 0 auto;">
      <div class="echarts" style="margin-top: 20px;overflow: hidden;box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);">
        <p>近半个月信用速查统计</p>
        <div id="myChart"></div>
      </div>
    </el-row>
  </div>
</template>

<script>
import service from "./api"
import echarts from "@/lib/echarts"
import dayjs from "dayjs"
import router from "@/router"
import info from "../../components/header/info" 

export default {
  components:{info},
  data() {
    return {
      entry: [{ name: '客户查询', icon: require('../../assets/img/home/blacklist.png'), path: '/risk' },
      { name: '真假财务', icon: require('../../assets/img/home/element.png'), path: '/really' }, { name: '黑名单库', icon: require('../../assets/img/home/account.png'), path: '/blacklist' },
      { name: '费用明细', icon: require('../../assets/img/home/finance.png'), path: '/finance/detail' }, { name: '修改密码', icon: require('../../assets/img/home/password.png') }],
      cardData: {},
    }
  },
  created() {
  },
  mounted() {
    this.getData();
  },
  computed: {
    //余额
    balance() {
      return this.$store.state.balance || 0;
    },
    // 卡片数据
    cardList() {
      let {
        certification = {},
        pageView = {},
        register = {},
        through = {},
      } = this.cardData;
      return [
        {
          name: "今日查询",
          icon: "el-icon-view",
          color: "#f93b7a",
          data: pageView,
        },
        {
          name: "累计查询",
          icon: "el-icon-user",
          color: "#00bcd4",
          data: register,
        },
        {
          name: "今日消费",
          icon: "el-icon-postcard",
          color: "#fbb624",
          data: certification,
        },
        {
          name: "累计消费",
          icon: "el-icon-document-checked",
          color: "#f93b7a",
          data: through,
        },
      ];
    }
  },
  methods: {
    openPage(item){
      if (item.path) {
        router.push(item.path);
      }else{
        this.$refs.resetRef.openReset()
      }
    },
    getData() {
      return service.statics().then((res) => {
        // 生成折线图数据
        let charts = { date: [], count: [] };
        res.forEach((item) => {
          charts.date.push(dayjs(item.date).format("MM-DD"));
          charts.count.push(item.count);
        });
        this.drawLine(charts)
      })
    },
    // 绘画曲线图
    drawLine(charts) {
      // 基于准备好的dom，初始化echarts实例

      let myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        color: ["#00bcd4"],
        tooltip: { trigger: "item" },
        legend: {
          data: ["查询次数"],
          icon: "circle",
          top: "bottom",
          color: "#000000",
          inactiveColor: "#555555",
        },
        xAxis: [{ type: "category", data: charts.date }],
        yAxis: [
          {
            type: "value",
            minInterval: 1
          },
        ],
        series: [
          {
            name: "查询次数",
            type: "line",
            data: charts.count,
            lineStyle: { width: 4 },
            itemStyle: { normal: { borderWidth: 4, borderColor: "#A9F387" } },
            areaStyle: { color: "#00bcd4" },
            smooth: true,
          }
        ],
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.gonggao {
  margin: 10px 0px 10px 0px;

}

.time {
  font-weight: 500;
  margin-bottom: 20px;
}

.info {
  font-size: 14px;
  color: #000;
}

.card-2 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-3 {
  margin-bottom: 20px;
  padding: 10px 10px 10px 0px;
}

.card-4 {
  margin-bottom: 20px;
  padding: 10px 10px 10px 0px;
}

.card {
  background: #ffffff;
  width: 23%;
  min-height: 36px;
  border-radius: 4px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  padding: 20px;
  position: relative;

  &-icon {
    color: #e2e2f1;
    font-size: 24px;
    font-weight: 700;
  }

  &-name {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 4px 6px;
    border-radius: 3px;
  }

  &-trend {
    margin-top: 10px;

    .up {
      color: #1ecab8 !important;
    }

    .down {
      color: #f93b7a !important;
    }
  }
}

.echarts {
  padding: 20px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 20px;

  #myChart {
    width: 100%;
    height: 275px;
  }
}

.kjrk {
  width: 80px;
  height: 80px;
  background-color: #0f75ff;
  border-radius: 8px;
  text-align: center;
  padding: 22px 0 0 0;
}

.el-card__body,
.el-main {
  padding: 26px 20px 30px 20px;
}

@media screen and (max-width: 480px) {
  .kjrk {
    width: 50px;
    height: 50px;
    background-color: #0f75ff;
    border-radius: 8px;
    text-align: center;
    padding: 15px 0 0 0;
  }

  .aders {
    font-size: 20px !important;
  }
  .gg-info{
    white-space: normal!important;
    width: 100%!important;
    overflow: hidden;
    font-size: 14px;
  }

}

@media screen and (max-width: 1180px) {
  .kjrk {
    width: 70px;
    height: 70px;
    background-color: #0f75ff;
    border-radius: 8px;
    text-align: center;
    padding: 15px 0 0 0;
  }
  .gg{
    margin-top: 20px;
  }

  .el-col-14 {
    width: 100%;
  }

  .el-col-10 {
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .gg-info{
    white-space: nowrap;
    width: 98%;
    overflow: hidden;
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .kjrk {
    width: 50px;
    height: 50px;
    background-color: #0f75ff;
    border-radius: 8px;
    text-align: center;
    padding: 15px 0 0 0;
  }

  .el-col-14 {
    width: 100%;
  }

  .el-col-10 {
    width: 100%;
  }
}

.ad01 {
  background: linear-gradient(45deg, rgba(254, 172, 94, 0.5), rgba(199, 121, 208, 0.5), rgba(75, 192, 200, 0.5));
}

.ad02 {
  background: linear-gradient(to left, #d3959b, #bfe6ba);
}

.aders {
  width: 100%;
  height: 88px;
  display: block;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 24px;
  text-align: center;
  line-height: 88px;
  font-weight: 400;
}
</style>

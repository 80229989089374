import API from "../../api/index";
import fa from "element-ui/src/locale/lang/fa";

export default {
  // 发送验证码
  getCode: params => {
    return API.requestPost(
      {
        url: "/api/sms/sendSms",
        data: params
      },
      false
    );
  },
  toLogin: params => {
    return API.requestPost({
      url: "user/login",
      data: params
    });
  },
  childLogin: params => {
    return API.requestPost({
      url: "son/login",
      data: params
    });
  },
  regist: params => {
    return API.requestPost({ url: "user/doRegister", data: params });
  },
  pv: params => {
    return API.requestGet({ url: "api/common/util/pv", params }, false);
  },
  // 注册前校验
  checkCode: params => {
    return API.requestPost({ url: "user/selectIsNormal", data: params });
  },
  //总后台注册
  statistics: params => {
    return API.requestGet({url: "api/v3/sponsored/statistics", params}, false);
  }
};
